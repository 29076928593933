// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import convertToDayjs from "@justpark/helpers/src/dates/convertToDayjs";
import getTimeIntervalsForIncrement from "@justpark/helpers/src/getTimeIntervalsForIncrement/getTimeIntervalsForIncrement";
import bem from "@justpark/helpers/src/bem/bem";
import SingleDatePickerDropDown from "../SingleDatePickerDropDown";
import NativeFancyDropdown from "../NativeFancyDropdown";
import Typography from "../Typography";

import "./dateTimeDropDown.scss";

type Props = {
  date: string;
  time: string;
  onDateChange: (a: string) => void;
  onTimeChange: (a: string) => void;
  datePlaceholder?: string;
  timePlaceholder?: string;
  label?: string;
  timeInterval?: number;
  hideDate?: boolean;
  className?: string;
  labelColor?: "primary" | "light";
  timeError?: string;
  timeDisabled?: boolean;
  t?: (key?: string, defaultString?: string) => string;
  min?: string | null;
};
const baseClass = "c-date-time-drop-down";
const DateTimeDropDown = ({
  date,
  time,
  onDateChange,
  onTimeChange,
  datePlaceholder = "",
  timePlaceholder = "",
  label,
  timeInterval = 30,
  hideDate = false,
  className = "",
  labelColor = "primary",
  timeError = "",
  timeDisabled = false,
  t = () => "Do MMM YYYY",
  min = ""
}: Props) => {
  // Ensure that initially display values is shown in the interval list
  // And that the value stays there even when changed
  const [initialTime, setInitialTime] = useState(time);
  useEffect(() => {
    if (initialTime === "") {
      setInitialTime(time);
    }
  }, [initialTime, setInitialTime, time]);
  const defaultOptions = useMemo(
    () =>
      getTimeIntervalsForIncrement(timeInterval, initialTime).map((value) => {
        const dateInstance = convertToDayjs(`2099-01-01 ${value}:00`);

        return {
          label: t("dateFormat:time", "{{date, HH:mm}}", {
            date: dateInstance
          }),
          value
        };
      }),
    [initialTime, t, timeInterval]
  );
  return (
    <div className={`${baseClass} ${className}`}>
      {label && (
        <label className={bem(baseClass, "label")}>
          <Typography size="secondary" bold color={labelColor}>
            <div className={bem(baseClass, "labeltext")}>{label}</div>
          </Typography>
        </label>
      )}
      <div className={bem(baseClass, "input-container")}>
        {!hideDate && (
          <SingleDatePickerDropDown
            onChange={onDateChange}
            error=""
            hideErrorCross
            date={date}
            placeholder={datePlaceholder}
            t={t}
            min={min}
          />
        )}

        <NativeFancyDropdown
          options={defaultOptions}
          selected={time}
          onChange={onTimeChange}
          placeholder={timePlaceholder}
          error={timeError}
          disabled={timeDisabled}
          t={t}
        />
      </div>
    </div>
  );
};
export default DateTimeDropDown;
