import React, { useEffect, useMemo } from 'react';
import { useParams } from '@not-remix-run/react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import Error from '@justpark/ui/src/components/Error';
import ListingDetails from '../components/ListingDetails/ListingDetails';
import GET_LISTING_DETAILS_QUERY from '../queries/listingDetails/getListingDetailsQuery.graphql';
import { gtagTrack, page } from '../helpers/Tracking/Analytics';
import mapSizes from '../helpers/listingDetailsMapSizes';
import LoadingState from '../components/ListingDetails/LoadingState';
import { getBreadCrumbs } from '../schema/breadcrumbs';
import webPage from '../schema/webPage';
import { audienceNodes } from '../schema/audience';

import styles from '../../static/scss/pages/listingDetails.module.scss';

const richResultsData = (canonical, listing) => {
  const localBusinessData = {
    '@type': ['ParkingFacility', 'LocalBusiness'],
    '@id': `${canonical}#/schema/ParkingFacility/1`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: listing.address?.line1,
      addressLocality: listing.address?.city,
      postalCode: listing.address?.postcode,
      addressCountry: listing.address?.country
    },
    url: listing.canonicalUrl,
    description: listing.description,
    image: listing?.photos?.[0]?.normal.url || '',
    geo: {
      '@type': 'GeoCoordinates',
      latitude: listing.address?.lat,
      longitude: listing.address?.lng
    },
    name: listing.title
  };

  if (listing.feedback?.count > 0) {
    localBusinessData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: listing.feedback?.averageRating,
      ratingCount: listing.feedback?.count
    };
  }

  return localBusinessData;
};

const reviewNodes = (canonical, listing) => {
  const reviews = listing.reviews?.nodes || [];

  return reviews.map((review) => ({
    '@type': 'Review',
    itemReviewed: {
      '@id': `${canonical}#/schema/ParkingFacility/1`
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: review.rating,
      bestRating: '5'
    },
    dateCreated: review.createdAt,
    author: {
      '@type': 'Person',
      name: review.sender.trim().length > 0 ? review.sender : 'Anonymous Driver'
    }
  }));
};

const useBreadcrumbsData = (canonical, breadcrumbs) =>
  useMemo(() => {
    if (!breadcrumbs || breadcrumbs.length === 0) {
      return {};
    }

    const breadcrumbData = breadcrumbs.map(({ text, url }) => ({
      title: text,
      url
    }));

    return getBreadCrumbs(canonical, breadcrumbData);
  }, [breadcrumbs, canonical]);

const ListingDetailsPage = () => {
  useEffect(() => {
    page('listing-details');
    gtagTrack('listing_landing_impression');
  }, []);

  const { slug } = useParams();

  const { loading, error, data, fetchMore } = useQuery(
    GET_LISTING_DETAILS_QUERY,
    {
      variables: {
        slug,
        reviewsOffset: 0,
        mapSizes,
        reviewsLimit: 10
      },
      // needed to allow loading state to be set when doing fetchMore
      notifyOnNetworkStatusChange: true
    }
  );

  const listing = data?.listing || {};

  // The BE endpoint will return a 422 on listing details when the listing model is not found
  // So we also need to check for that 422 as well as the 404 on slug lookup in order to work out if we are g2g

  // (loading && data) needed so that we don't reload ListingDetails component when doing fetchMore
  const isOk = (loading === false && !error) || (loading && data);

  const shouldShowLoading = loading && !data;

  const meta = listing?.meta || [];

  const { canonicalUrl, pageTitle } = listing;

  const breadCrumbData = useBreadcrumbsData(canonicalUrl, listing.breadcrumbs);

  const description =
    meta.find(({ key }) => key === 'description')?.value || '';

  const about = richResultsData(canonicalUrl, listing);
  const reviews = reviewNodes(canonicalUrl, listing);

  const webpageData = webPage({
    canonical: canonicalUrl,
    title: pageTitle,
    description,
    breadcrumbs: breadCrumbData,
    about,
    nodes: reviews,
    audience: audienceNodes(listing.address?.country)
  });

  const lcpImage = listing.photos?.[0]?.normal.url || null;

  return (
    <>
      <Helmet title={pageTitle} titleTemplate="%s">
        {meta.map(({ key, value, type }) => {
          if (type === 'property') {
            return <meta key={key} property={key} content={value} />;
          }

          return <meta key={key} name={key} content={value} />;
        })}
        {isOk && <link rel="canonical" href={listing.canonicalUrl} />}

        {isOk && (
          <script
            key="breadcrumbs"
            type="application/ld+json"
            data-cy="structured-data"
          >
            {JSON.stringify(webpageData)}
          </script>
        )}

        {lcpImage && <link rel="preload" as="image" href={lcpImage} />}
      </Helmet>
      <div className={styles.wrapper}>
        {shouldShowLoading && <LoadingState />}
        {error && <Error error={error.toString()} />}
        {isOk && (
          <ListingDetails
            location={listing}
            fetchMore={fetchMore}
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default ListingDetailsPage;
